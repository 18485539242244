import { render, staticRenderFns } from "./CourseDetailsPopover.vue?vue&type=template&id=c5c521a8"
import script from "./CourseDetailsPopover.vue?vue&type=script&lang=js"
export * from "./CourseDetailsPopover.vue?vue&type=script&lang=js"
import style0 from "./CourseDetailsPopover.vue?vue&type=style&index=0&id=c5c521a8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports