<template>
  <b-popover
    :target="`course-card-${course.course_id}`"
    triggers="hover"
    placement="center"
  >
    <div class="course-card__popover">
      <div class="course-card__popover--div">
        <h6>
          <img
            src="@/assets/images/partner/course-api-request/partner.svg"
            alt="partner"
          />
          Partner
        </h6>
        <ul>
          <li>Short Name: {{ userInfo.partner.org_short_name }}</li>
          <li>Description: {{ userInfo.partner.short_description }}</li>
        </ul>
      </div>
      <div class="course-card__popover--div">
        <h6>
          <img
            src="@/assets/images/layout/partner-layout/sidenav/menu/modules.svg"
            alt="courses"
          />
          Course Information
        </h6>
        <ul>
          <li>Course name: {{ course.name }}</li>
          <li>Course ID: {{ course.course_id }}</li>
          <li>
            Start Date:
            {{ course.start ? formatDate(course.start) : "Not specified" }}
          </li>
          <li>
            End Date:
            {{ course.end ? formatDate(course.end) : "Not specified" }}
          </li>
          <li>Enrollment Period: Not specified</li>
        </ul>
      </div>
      <div class="course-card__popover--div">
        <h6>
          <img
            src="@/assets/images/partner/course-api-request/calendar.svg"
            alt="calendar"
          />
          Calendar
        </h6>
        <ul>
          <li>
            Created:
            {{
              course.created_at
                ? formatDate(course.created_at)
                : "Not specified"
            }}
          </li>
          <li>
            Last Updated:
            {{
              course.updated_at
                ? formatDate(course.updated_at)
                : "Not specified"
            }}
          </li>
        </ul>
      </div>
    </div>
  </b-popover>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    }
  }
};
</script>
<style lang="scss">
.course-card__popover {
  .course-card__popover--div {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
      ul {
        margin-bottom: 0;
      }
    }
    h6 {
      @include body-regular(500);
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      img {
        margin-right: 8px;
      }
    }
    ul {
      padding-left: 1.5rem;
      li {
        @include label-large;
      }
    }
  }
}
</style>
